export default {
  routes: [
    {
      path: "/404",
      name: "404",
      component: () => import("./404.vue"),
      meta: {
        title: "404",
        roles: [""],
        permissions: [""],
      },
    },

    {
      path: "/no-permission",
      name: "no-permission",
      component: () => import("./NoPermission.vue"),
      meta: {
        title: "no permission",
        roles: [""],
        permissions: [""],
      },
    },
  ],
};
