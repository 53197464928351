import { defineAsyncComponent } from "vue";

const components = [
  {
    name: "AttachFile",
    component: defineAsyncComponent(
      () => import("@/components/common/AttachFile.vue"),
    ),
  },
  {
    name: "MapView",
    component: defineAsyncComponent(
      () => import("@/components/common/MapView.vue"),
    ),
  },
  {
    name: "AccordionPanelCard",
    component: defineAsyncComponent(
      () => import("@/components/ui/AccordionPanelCard.vue"),
    ),
  },
  {
    name: "AttachImage",
    component: defineAsyncComponent(
      () => import("@/components/common/AttachImage.vue"),
    ),
  },
  {
    name: "Button",
    component: defineAsyncComponent(() => import("@/components/ui/Button.vue")),
  },
  {
    name: "DataTable",
    component: defineAsyncComponent(
      () => import("@/components/common/DataTable.vue"),
    ),
  },
  {
    name: "InputText",
    component: defineAsyncComponent(
      () => import("@/components/common/InputText.vue"),
    ),
  },
  {
    name: "RadioInput",
    component: defineAsyncComponent(
      () => import("@/components/common/RadioInput.vue"),
    ),
  },
  {
    name: "SelectDate",
    component: defineAsyncComponent(
      () => import("@/components/common/SelectDate.vue"),
    ),
  },
  {
    name: "BreadCrumb",
    component: defineAsyncComponent(
      () => import("@/components/ui/BreadCrumb.vue"),
    ),
  },
  {
    name: "SearchInput",
    component: defineAsyncComponent(
      () => import("@/components/common/InputAutoComplete.vue"),
    ),
  },
  {
    name: "SelectTime",
    component: defineAsyncComponent(
      () => import("@/components/common/SelectTime.vue"),
    ),
  },
  {
    name: "Modal",
    component: defineAsyncComponent(() => import("@/components/ui/Modal.vue")),
  },
  {
    name: "SingleSelect",
    component: defineAsyncComponent(
      () => import("@/components/common/SingleSelect.vue"),
    ),
  },
  {
    name: "MessageBox",
    component: defineAsyncComponent(
      () => import("@/components/ui/MessageBox.vue"),
    ),
  },
  {
    name: "Loader",
    component: defineAsyncComponent(() => import("@/components/ui/Loader.vue")),
  },
  {
    name: "ColorPicker",
    component: defineAsyncComponent(
      () => import("@/components/common/ColorPicker.vue"),
    ),
  },
  {
    name: "FilterGroup",
    component: defineAsyncComponent(
      () => import("@/components/common/FilterGroup.vue"),
    ),
  },

  {
    name: "ChatCards",
    component: defineAsyncComponent(
      () => import("@/components/ui/ChatCards.vue"),
    ),
  },
  {
    name: "MessageAttachment",
    component: defineAsyncComponent(
      () => import("@/components/ui/MessageAttachment.vue"),
    ),
  },
  {
    name: "MessagesView",
    component: defineAsyncComponent(
      () => import("@/components/ui/MessagesView.vue"),
    ),
  },
];

export default {
  install: function (app) {
    components.forEach((component) => {
      const name = `Mc${component.name}`;
      app.component(name, component.component);
    });
  },
};
