import PrimeInit from "./PrimeVue";
import { createPinia } from "pinia";
import router from "@/router";
import i18n from "./I18n";
import VueDatePicker from "./DatePicker";
import VueApexCharts from "vue3-apexcharts";
import pusher from "./pusher";
// Icons
import { VsxIcon } from "vue-iconsax";

import ComponentInit from "./InitComponents";
import axiosClient from "@/helpers/http.helper";

import VueCookies from "vue-cookies";

import ToastService from "primevue/toastservice";
import ToastEventBus from "primevue/toasteventbus";
import Tooltip from "primevue/tooltip";

import VueClickAway from "vue3-click-away";
import { userHasAction } from "../helpers/permissions.helper";
export default {
  install: async function (app) {
    await app.use(createPinia());
    app.use(i18n);
    app.use(VueApexCharts);
    app.use(VueCookies);
    app.use(ToastService);
    app.use(router);

    PrimeInit.install(app);
    ComponentInit.install(app);

    app.config.globalProperties.$toastEventBus = ToastEventBus;
    app.config.globalProperties.$pusher = pusher;
    app.config.globalProperties.$userHasAction = userHasAction;
    // component
    app.component("VueDatePicker", VueDatePicker);
    app.component("VsxIcon", VsxIcon);

    window.axios = axiosClient;
    app.directive("tooltip", Tooltip);
    app.use(VueClickAway);

    //
    app.mount("#app");
  },
};
