export default {
  routes: [
    {
      path: "/dashboard",
      component: () => import("./WrapperHome.vue"),
      name: "connect-index-dashboard",
      meta: {
        title: "Dashboard",
        roles: [""],
        permissions: ["can_access_dashboard"],
      },
      redirect: { name: "connect-dashboard" },
      children: [
        {
          path: "overview",
          name: "connect-dashboard",
          component: () => import("./Dashboard/Dashboard.vue"),
          meta: {
            title: "Dashboard",
            roles: [""],
            permissions: ["can_access_dashboard"],
          },
        },
        {
          path: "reports-details",
          name: "reports-details",
          component: () => import("./ReportingDetails/ReportingDetails.vue"),
          meta: {
            title: "Reporting Details",
            roles: [""],
            permissions: ["can_access_reporting_details"],
          },
        },
        {
          path: "recent-activity",
          name: "recent-activity",
          component: () => import("./RecentActivity/RecentActivity.vue"),
          meta: {
            title: "Recent Activity",
            roles: [""],
            permissions: ["can_access_recent_activity"],
          },
        },
        {
          path: "import-export",
          name: "import-export",
          component: () => import("./ImportExport/ImportExport.vue"),
          meta: {
            title: "Import Export",
            roles: [""],
            permissions: [
              "can_start_import_process_dashboard",
              "can_export_selected_data_dashboard",
            ],
          },
        },
      ],
    },
  ],
};
