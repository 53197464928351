export default {
  routes: [
    {
      path: "/owners",
      name: "admin-owners",
      component: () => import("./WrapperOwners.vue"),
      redirect: { name: "admin-owners-list" },
      meta: {
        title: "Owners",
        roles: [""],
      },
      children: [
        {
          path: "",
          name: "admin-owners-list",
          component: () => import("./OwnersList.vue"),
          meta: {
            title: "Owners",
            roles: [""],
          },
        },
        {
          path: "create",
          name: "admin-create-owners",
          component: () => import("./AdminCreateOwners.vue"),
          meta: {
            title: "create owners",
            roles: [""],
          },
        },
      ],
    },
  ],
};
