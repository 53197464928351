export default {
  routes: [
    {
      path: "/unit-residents",
      name: "unit-residents",
      redirect: "/",
      meta: {
        title: "Unit Residents",
        roles: [""],
      },
      children: [
        {
          path: "",
          name: "unit-residents-list",
          component: () => import("./UnitResidentsList.vue"),
          meta: {
            roles: [""],
            permissions: ["can_access_unit_residents", "can_list_unit_residents"],
          },
        },
        {
          path: "create",
          name: "create-unit-resident",
          component: () => import("./NewUnitResident.vue"),
          meta: {
            roles: [""],
            permissions: ["can_create_unit_residents"],
          },
        },
        {
          path: ":id/edit",
          name: "edit-unit-resident",
          props: true,
          component: () => import("./NewUnitResident.vue"),
          meta: {
            roles: [""],
            permissions: ["can_update_unit_residents"],
            isEdit: true,
          },
        },
        {
          path: ":id/details",
          name: "unit-resident-details",
          props: true,
          component: () => import("./UnitResidentDetails.vue"),
          meta: {
            roles: [""],
            permissions: ["can_view_unit_residents"],
          },
        },
      ],
    },
  ],
};
