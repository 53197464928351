export default {
  routes: [
    {
      path: "/users",
      component: () => import("./WrapperUsers.vue"),
      redirect: { name: "admin-users-list" },
      meta: {
        title: "users",
        roles: [""],
      },
      children: [
        {
          path: "",
          name: "admin-users-list",
          component: () => import("./UsersList.vue"),
          meta: {
            title: "users",
            roles: [""],
          },
        },
      ],
    },
  ],
};
