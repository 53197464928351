export default {
  routes: [
    {
      path: ":id",
      name: "show-vendors",
      component: () => import("./WrapperShowVendor.vue"),
      meta: {
        title: "Vendors & Teams",
        permissions: ["can_view_vendors"],
        roles: [""],
      },
      redirect: { name: "show-vendors-details" },
      children: [
        {
          path: "details",
          name: "show-vendors-details",
          component: () => import("./ShowVendorsDetails.vue"),
          meta: {
            permissions: ["can_view_vendors"],
            title: "Details",
            roles: [""],
          },
        },
        {
          path: "payments",
          name: "show-vendors-payments",
          component: () => import("./VendorsPayment.vue"),
          meta: {
            title: "Spend and Payment",
            permissions: ["can_view_vendors"],
            roles: [""],
          },
        },
        {
          path: "locations",
          name: "show-vendors-locations",
          component: () => import("./VendorsLocations.vue"),
          meta: {
            permissions: ["can_view_vendors"],
            title: "Locations",
            roles: [""],
          },
        },
      ],
    },
  ],
};
