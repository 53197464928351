import showSiteRoute from "./ShowSite/show.site.route";

export default {
  routes: [
    {
      path: "/sites",
      component: () => import("./SitesIndex.vue"),
      redirect: { name: "sites" },
      meta: {
        title: "Sites",
        roles: [""],
        permissions: ["can_list_sites", "can_access_sites"],
      },
      children: [
        {
          path: "",
          name: "sites",
          component: () => import("./WrapperSites.vue"),
          meta: {
            title: "Site",
            roles: [""],
            isEdit: false,
            permissions: ["can_list_sites", "can_access_sites"],
          },
        },
        {
          path: "create",
          name: "create-site",
          component: () => import("./CreateSite/CreateSite.vue"),
          meta: {
            title: "Create Site",
            roles: [""],
            isEdit: false,
            permissions: ["can_create_sites"],
          },
        },
        {
          path: "edit/:id",
          name: "edit-site",
          component: () => import("./CreateSite/CreateSite.vue"),
          meta: {
            title: "Edit Site",
            roles: [""],
            isEdit: true,
            permissions: ["can_update_sites"],
          },
        },
        {
          path: "create/:id",
          name: "show-create-site",
          component: () => import("./CreateSite/ShowSiteCreated.vue"),
          meta: {
            title: "show Create Site",
            roles: [""],
            isEdit: false,
            permissions: ["can_create_sites"],
          },
        },
        {
          path: "",
          name: "show-site-index",
          component: () => import("./ShowSite/IndexShowSite.vue"),
          redirect: { name: "show-site" },
          children: [...showSiteRoute.routes],
        },
      ],
    },
  ],
};
