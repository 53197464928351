export default {
  routes: [
    {
      path: ":id",
      name: "show-unit",
      component: () => import("./WrapperShowUnit.vue"),
      meta: {
        title: "unit",
        roles: [""],
        permissions: ["can_view_units"],
      },
      redirect: { name: "show-unit-details" },
      children: [
        {
          path: "details",
          name: "show-unit-details",
          component: () => import("./ShowUnitDetails.vue"),
          meta: {
            title: "Unit",
            permissions: ["can_view_units"],
            roles: [""],
          },
        },
        {
          path: "customer-history",
          name: "show-unit-customer-history",
          component: () => import("./ShowUnitCustomerHistory.vue"),
          meta: {
            title: "Unit",
            permissions: ["can_view_units"],
            roles: [""],
          },
        },
      ],
    },
  ],
};
