export default {
  routes: [
    {
      path: "invoices",
      name: "invoices",
      component: () => import("./Invoices.vue"),
      meta: {
        title: "invoices",
        permissions: ["can_access_administration_invoices"],
        roles: [],
      },
    },
    {
      path: "payment-methods",
      name: "payment-methods",
      component: () => import("./PaymentMethod.vue"),
      meta: {
        title: "payment-methods",
        permissions: ["can_access_administration_payment_methods"],
        roles: [],
      },
    },
    {
      path: "contacts",
      name: "contacts",
      component: () => import("./BillingContacts.vue"),
      meta: {
        title: "billing-contacts",
        permissions: ["can_access_administration_contacts"],
        roles: [],
      },
    },
  ],
};
