import Auth from "@/services/auth/auth.services";
import UserAccount from "@/services/auth/account.services";
import { defineStore } from "pinia";
import router from "@/router";
import { extractKeyword } from "../../helpers/url.helper";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    user: null,
    permissions: null,
    token: null,
    companies: null,
    settings: null,
    isLoading: false,
  }),
  getters: {
    getPermissions: ($state) => () => {
      return $state.permissions;
    },
  },
  actions: {
    async login (obj) {
      this.isLoading = true;
      try {
        const { data } = await Auth.login(obj);
        this.user = data.data.user;
        this.token = data.data.token;
        this.companies = data.data.companies;
        this.settings = data.data.setting;
        this.permissions = data.data.permissions;

        window.$cookies.set(
          `${extractKeyword(window.location.href)}_mc_token`,
          this.token,
          "2d",
        );
        window.$cookies.set(
          `${extractKeyword(window.location.href)}_mc_user`,
          JSON.stringify(this.user),
          "2d",
        );
        window.$cookies.set(
          `${extractKeyword(window.location.href)}_mc_companies`,
          JSON.stringify(this.companies),
          "2d",
        );

        if (extractKeyword(window.location.href) == "admin") {
          await router.push({ name: "admin-dashboard" });
        } else if (extractKeyword(window.location.href) == "vendor") {
          await router.push({ name: "vendor-dashboard" });
        } else {
          await router.push({ name: "connect-dashboard" });
        }
      } catch (error) {
        console.log(error);

        return error.response.data;
      } finally {
        this.isLoading = false;
      }
    },

    checkAuth: async function () {
      this.isLoading = true;
      try {
        const token = window.$cookies.get(
          `${extractKeyword(window.location.href)}_mc_token`,
        );

        if (token) {
          const { data } = await Auth.getMe();
          window.$cookies.set(
            `${extractKeyword(window.location.href)}_mc_user`,
            JSON.stringify(data.data.user),
            "2d",
          );
          window.$cookies.set(
            `${extractKeyword(window.location.href)}_mc_companies`,
            JSON.stringify(data.data.companies),
            "2d",
          );

          const companies = window.$cookies.get(
            `${extractKeyword(window.location.href)}_mc_companies`,
          );

          const user = window.$cookies.get(
            `${extractKeyword(window.location.href)}_mc_user`,
          );

          this.token = token;
          this.user = user;
          this.companies = companies;
          this.settings = data.data.setting;
          this.permissions = data.data.permissions;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    changeDefaultCompany: async function (obj) {
      this.isLoading = true;
      try {
        await UserAccount.changeDefaultCompany(obj);
        await this.checkAuth();
        return true;
        // this.companies = data.data.companies;
        // window.$cookies.set("mc_companies", JSON.stringify(this.companies));
      } catch (error) {
        return false;
      } finally {
        this.isLoading = false;
      }
    },
    logoutAccount: async function () {
      try {
        this.isLoading = true;
        await Auth.logout();
        window.$cookies.remove(
          `${extractKeyword(window.location.href)}_mc_user`,
        );
        window.$cookies.remove(
          `${extractKeyword(window.location.href)}_mc_companies`,
        );
        window.$cookies.remove(
          `${extractKeyword(window.location.href)}_mc_token`,
        );
        await router.push({ name: "login" });
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    completeRegistration: async function (obj) {
      this.isLoading = true;
      try {
        await Auth.completeRegistration(obj);
        await router.push({ name: "login" });
      } catch (error) {
        console.log(error);
        return error.response.data;
      } finally {
        this.isLoading = false;
      }
    },
  },
});
